<template>
  <div class="lg:col-span-1">
    <div class="text-xs font-semibold lg:text-sm">
      {{ title }}
    </div>
    <div class="text-xs lg:text-sm">
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    value: String
  }
};
</script>
