<template>
  <div :class="['p-6 flex', collapsed ? '' : 'hidden']">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    collapsed: Boolean
  }
};
</script>
