<template>
  <Accordion class="insurance-card p-0" no-padding>
    <accordion-header
      :collapsed="internal_collapsed"
      @click.native="internal_collapsed = !internal_collapsed"
    >
      <div class="flex flex-col lg:flex-row font-serif">
        <h1
          :class="[
            active ? 'text-brand-purple-500' : '',
            'text-lg font-medium leading-6 mr-5'
          ]"
        >
          {{ contract.contract_description }}
        </h1>
        <div class="text-sm font-light text-brand-gray-500">
          Polisnummer: {{ contract.navision_ext_id }}
        </div>
      </div>
    </accordion-header>
    <accordion-body
      :collapsed="internal_collapsed"
      class="flex flex-col lg:flex-row"
    >
      <div class="w-full">
        <div class="grid grid-cols-2 lg:grid-cols-6 gap-4">
          <insurance-detail-item
            :title="$t('insurances.form.policy_number')"
            :value="contract.navision_ext_id"
          />
          <insurance-detail-item
            :title="$t('insurances.form.starting_date')"
            :value="this.format_date(contract.starting_date, 'DD-MM-YYYY')"
          />
          <insurance-detail-item
            :title="$t('insurances.form.expiration_date')"
            :value="
              contract.calculated_ending_date === '0001-01-01' ||
              contract.calculated_ending_date == null
                ? '-'
                : this.format_date(
                    contract.calculated_ending_date,
                    'DD-MM-YYYY'
                  )
            "
          />
          <insurance-detail-item
            :title="$t('insurances.form.closed_via')"
            :value="contract.store"
          />
          <insurance-detail-item
            :title="$t('insurances.form.claims')"
            :value="`${contract.claims_count}`"
          />
          <insurance-detail-item
            :title="$t('insurances.form.multidevice')"
            :value="contract.multidevice ? $t('common.yes') : $t('common.no')"
          />
        </div>
        <div
          class="pb-1 pt-6 leading-8 font-serif flex flex-wrap justify-between"
        >
          <div
            class="flex border-b border-t w-full flex-col col-span-12 py-3 lg:border-b-0 lg:border-t-0 lg:flex-row w-auto"
          >
            <div class="flex items-center mr-5">
              <n-icon name="Pdf" class="mr-2" small />
              <n-button-link
                @click.native="downloadPolicyConditions(contract.id, 'ipid')"
              >
                <n-html :text="$t('insurances.actions.download.ipid')" />
              </n-button-link>
            </div>
            <div class="flex items-center mr-5">
              <n-icon name="Pdf" class="mr-2" small />
              <n-button-link
                @click.native="
                  downloadPolicyConditions(contract.id, 'policy_sheet')
                "
              >
                <n-html
                  :text="$t('insurances.actions.download.policy_sheet')"
                />
              </n-button-link>
            </div>
            <div class="flex items-center mr-5">
              <n-icon name="Pdf" class="mr-2" small />
              <n-button-link
                @click.native="
                  downloadPolicyConditions(contract.id, 'policy_conditions')
                "
              >
                <n-html
                  :text="$t('insurances.actions.download.policy_conditions')"
                />
              </n-button-link>
            </div>
          </div>
          <div class="flex mt-5 items-center lg:mt-0">
            <div
              v-if="active"
              class="flex flex-wrap items-center gap-6 md:gap-4"
            >
              <n-button-regular
                class="float:left"
                @click.native="showAddModal(true)"
                secondary
                :disabled="contract.product_set.length >= 5"
                :title="
                  contract.product_set.length >= 5
                    ? 'Maximaal aantal producten bereikt.'
                    : ''
                "
              >
                <n-html :text="$t('insurances.actions.add')" />
              </n-button-regular>
              <n-button-regular secondary @click.native="showCancelModal(true)">
                <n-html :text="$t('insurances.actions.cancel')" />
              </n-button-regular>
            </div>
          </div>
        </div>
        <div v-if="contract.product_set" class="w-full mt-5">
          <div
            v-bind:key="index"
            v-for="(product, index) in contract.product_set"
          >
            <div class="flex bg-purple-50 px-6 py-2 -mx-6 mt-6">
              <span class="uppercase text-xs font-semibold">{{
                product.description
              }}</span>
              <div class="ml-auto cursor-pointer" xs>
                <n-button-link
                  variant="cancel"
                  @click.native="
                    () => {
                      product_to_cancel = product;
                      showProductDeleteModal(true);
                    }
                  "
                >
                  {{ $t("insurances.actions.remove_product") }}
                </n-button-link>
              </div>
            </div>
            <div class="grid grid-cols-2 lg:grid-cols-6 gap-4 mt-6">
              <n-icon
                class="hidden lg:block text-black"
                :name="product.category_code"
              />
              <insurance-detail-item
                :title="$t('insurances.form.serial_number')"
                :value="product.serial_number"
              />
              <insurance-detail-item
                :title="$t('insurances.form.imei')"
                :value="product.imei ? product.imei : '-'"
              />
              <insurance-detail-item
                :title="$t('insurances.form.object_value')"
                :value="
                  product.object_value
                    ? '€ ' + product.object_value.toFixed(2)
                    : '-'
                "
              />
              <insurance-detail-item
                :title="$t('insurances.form.manufacturer')"
                :value="product.manufacturer ? product.manufacturer : '-'"
              />
            </div>
          </div>
        </div>
      </div>
    </accordion-body>
    <modal-insurance-add
      v-if="contract.multidevice"
      :contract="contract"
      :show="add_modal_visible"
      :onCloseModal="
        () => {
          showAddModal(false);
          this.$forceUpdate();
        }
      "
    />
    <modal-insurance-edit
      :contract="contract"
      :show="edit_modal_visible"
      :onCloseModal="() => showEditModal(false)"
    />
    <modal-insurance-cancel
      :contract="contract"
      :show="cancel_modal_visible"
      :onCloseModal="() => showCancelModal(false)"
    />
    <modal-insurance-cancel-product
      :contract-id="contract.id"
      :product="product_to_cancel"
      :show="delete_product_modal_visible"
      @onCloseModal="
        () => {
          this.showProductDeleteModal(false);
          this.product_to_cancel = null;
        }
      "
    />
  </Accordion>
</template>

<script>
import ModalInsuranceCancel from "./modal-insurance-cancel";
import ModalInsuranceEdit from "./modal-insurance-edit";
import ModalInsuranceAdd from "./modal-insurance-add";
import format from "../../mixins/format";
import { downloadFile } from "@/utils";
import AccordionHeader from "@/components/theme/accordion/accordion-header";
import Accordion from "@/components/theme/accordion/accordion";
import AccordionBody from "@/components/theme/accordion/accordion-body";
import InsuranceDetailItem from "@/components/insurances/details/insurance-detail-item";
import ModalInsuranceCancelProduct from "@/components/insurances/modal-insurance-cancel-product.vue";

export default {
  name: "components.insurances.card-insurance-multidevice-details",
  components: {
    InsuranceDetailItem,
    AccordionBody,
    Accordion,
    AccordionHeader,
    ModalInsuranceAdd,
    ModalInsuranceEdit,
    ModalInsuranceCancel,
    ModalInsuranceCancelProduct
  },
  mixin: [format],
  data: () => ({
    internal_collapsed: true,
    cancel_modal_visible: false,
    add_modal_visible: false,
    edit_modal_visible: false,
    delete_product_modal_visible: false,
    product_to_cancel: null
  }),
  props: {
    contract: Object,
    collapsed: Boolean,
    active: Boolean
  },
  methods: {
    downloadPolicyConditions(id, file_type) {
      this.$store
        .dispatch("contracts/get_file", { id, file_type })
        .then(response => {
          downloadFile(response.data.dataUri, response.data.filename);
        });
    },
    showCancelModal(value) {
      this.cancel_modal_visible = value;
    },
    showAddModal(value) {
      this.add_modal_visible = value;
    },
    showEditModal(value) {
      this.edit_modal_visible = value;
    },
    showProductDeleteModal(value) {
      this.delete_product_modal_visible = value;
    }
  },
  mounted() {
    this.internal_collapsed = this.collapsed;
  }
};
</script>

<style>
.insurance-card .card-body {
  padding: 0;
}

.insurance-card .card-body .insurance-card-title {
  cursor: pointer;
}

.insurance-card .card-body .insurance-card-title .insurance-card-title-icon {
  right: 20px;
  bottom: 25px;
  position: absolute;
}

.insurance-card-footer-left div {
  float: left;
}

.mobile .insurance-card-footer-left {
  display: grid;
  margin-bottom: 10px;
}

.insurance-card-footer-right {
  float: right;
  position: relative;
}

.insurance-card-footer-right a {
  margin-left: 15px;
}

.mobile .insurance-card-footer-right {
  float: left;
}
</style>
