<template>
  <modal
    :class="show ? '' : 'hidden'"
    v-if="contract != null"
    @close="close()"
    :title="getTitle"
    :small="mobile"
  >
    {{ /* Steps */ }}
    <div class="sm:px-12 px-0 py-5">
      <div v-if="step === 1">
        <p class="text-sm">
          <n-html :text="$t('insurances.edit_modal.preview.subtitle')" />
        </p>
        <h2
          class="mt-4 sm:text-brand-purple-500 text-black font-semibold sm:text-lg text-sm"
        >
          <n-html :text="$t('insurances.edit_modal.preview.contract_title')" />
        </h2>
        <p class="py-4">
          <CardProductSmall :contract="contract" dense :useBorder="true" />
        </p>
        <n-alert variant="primary">
          <div class="flex flex-row font-semibold text-sm">
            <n-icon name="Info" class="mr-2"></n-icon>
            <n-html
              :text="$t('insurances.cancel_modal.preview.balloon.title')"
            />
          </div>
          <div class="text-brand-purple-500 text-sm mt-2">
            {{
              contract.late_payment_amount > 0
                ? $t("insurances.edit_modal.preview.balloon.sub_title_failed")
                : $t("insurances.edit_modal.preview.balloon.sub_title")
            }}
          </div>
        </n-alert>
      </div>
      <div v-if="step === 2">
        <CardProductSmall :contract="contract" dense :useBorder="true" />
        <div>
          <h2
            class="mt-4 pb-2 sm:text-brand-purple-500 text-black font-semibold sm:text-lg text-sm border-b"
          >
            <n-html
              :text="$t('insurances.edit_modal.description.form.title')"
            />
          </h2>
          <div class="pt-4">
            <div>
              <p class="pb-1 text-sm font-semibold">
                <n-html :text="$t('form.contract.edit.device_type.label')" />
              </p>
              <n-select
                :selected="contract.category_code"
                :options="deviceTypes"
                border
                disabled
              />
            </div>
            <div class="mt-2">
              <p class="pb-1 text-sm font-semibold">
                <n-html :text="$t('form.contract.edit.manufacturer.label')" />
              </p>
              <n-select-search
                :placeholder="$t('form.contract.edit.manufacturer.placeholder')"
                :oninput="onManufacturerChange"
                :options="manufacturer_options"
                :value="form.manufacturer"
              />
            </div>
            <div class="mt-2">
              <p class="pb-1 text-sm font-semibold">
                <n-html :text="$t('form.contract.edit.device_name.label')" />
              </p>
              <n-form-group id="input-group-device_name">
                <n-form-input-default
                  id="input-device_name"
                  :placeholder="
                    $t('form.contract.edit.device_name.placeholder')
                  "
                  v-model="form.device_name"
                  field_type="text"
                  maxlength="80"
                />
              </n-form-group>
            </div>
            <div class="mt-2">
              <p class="pb-1 text-sm font-semibold">
                <n-html :text="$t('form.contract.edit.purchase_date.label')" />
              </p>
              <n-form-group id="input-group-purchase_date">
                <n-form-input-default
                  id="input-purchase_date"
                  :placeholder="
                    $t('form.contract.edit.purchase_date.placeholder')
                  "
                  v-model="form.purchase_date"
                  field_type="date"
                  :minDate="minDate"
                  :maxDate="maxDate"
                  :auto-apply="true"
                />
              </n-form-group>
            </div>
            <div class="mt-2">
              <label class="pb-1 text-sm font-semibold">
                <n-html :text="$t('form.contract.edit.object_value.label')" />
              </label>
              <n-form-group id="input-group-object_value">
                <n-form-input-default
                  id="input-object_value"
                  :placeholder="
                    $t('form.contract.edit.object_value.placeholder')
                  "
                  v-model="form.object_value"
                  type="number"
                />
              </n-form-group>
            </div>
            <div class="mt-2">
              <label class="pb-1 text-sm font-semibold">
                <n-html :text="$t('form.contract.edit.article_no.label')" />
              </label>
              <n-form-group id="input-group-article_no">
                <n-form-input-default
                  id="input-article_no"
                  :placeholder="$t('form.contract.edit.article_no.placeholder')"
                  v-model="form.article_no"
                  field_type="text"
                  maxlength="50"
                />
              </n-form-group>
            </div>
            <div v-if="contract.category_code === 'PHONE'" class="mt-2">
              <p class="pb-1 text-sm font-semibold">
                <n-html :text="$t('form.contract.edit.imei.label')" />
              </p>
              <n-form-group id="input-group-imei">
                <n-form-input-default
                  id="input-imei"
                  :placeholder="$t('form.contract.edit.imei.placeholder')"
                  v-model="form.imei"
                  field_type="text"
                  maxlength="15"
                  :state="this.errors.imei"
                />
              </n-form-group>
              <span class="text-sm">
                <n-html :text="$t('form.contract.edit.imei.subtitle')" />
              </span>
            </div>
            <div v-else class="mt-2">
              <label class="pb-1 text-sm font-semibold">
                <n-html :text="$t('form.contract.edit.serial_number.label')" />
              </label>
              <n-form-group id="input-group-serial_number">
                <n-form-input-default
                  id="input-serial_number"
                  :placeholder="
                    $t('form.contract.edit.serial_number.placeholder')
                  "
                  v-model="form.serial_number"
                  field_type="text"
                  maxlength="30"
                />
              </n-form-group>
              <span class="text-sm">
                <n-html
                  :text="$t('form.contract.edit.serial_number.subtitle')"
                />
              </span>
            </div>
            <div class="mt-2">
              <label class="pb-1 text-sm font-semibold">
                <n-html
                  :text="$t('form.contract.edit.store_of_purchase.label')"
                />
              </label>
              <n-form-group id="input-group-store_of_purchase">
                <n-form-input-default
                  id="input-store_of_purchase"
                  :placeholder="
                    $t('form.contract.edit.store_of_purchase.placeholder')
                  "
                  v-model="form.store_of_purchase"
                  field_type="text"
                  maxlength="50"
                />
              </n-form-group>
            </div>
            <div class="mt-6">
              <h2
                class="mt-4 pb-2 text-brand-purple-500 font-semibold text-lg border-b"
              >
                <n-html :text="$t('form.contract.edit.picture.label')" />
              </h2>
              <n-form-group id="input-group-picture" class="mt-4">
                <insurance-image-upload
                  @imageSelected="d => this.onImageUpload(d)"
                />
              </n-form-group>
            </div>
          </div>
        </div>
      </div>
      <div v-if="step === 3">
        <CardProductSmall :contract="contract" dense :useBorder="true" />
        <div>
          <h2
            class="mt-4 pb-2 text-brand-purple-500 font-semibold text-lg border-b"
          >
            <n-html :text="$t('insurances.edit_modal.review.title')" />
          </h2>
          <div class="border-b pb-1 pt-3 leading-8 font-serif grid grid-cols-2">
            <div>
              <div class="font-semibold">
                <n-html
                  :text="$t('insurances.edit_modal.review.form.insurance')"
                />
              </div>
              <div>{{ contract.contract_description }}</div>
            </div>
            <div>
              <div class="font-semibold">
                <n-html
                  :text="$t('insurances.cancel_modal.review.form.product')"
                />
              </div>
              <div>{{ contract.description }}</div>
            </div>
          </div>
          <div class="border-b pb-1 pt-3 leading-8 font-serif grid grid-cols-2">
            <div>
              <div class="font-semibold">
                <n-html :text="$t('form.contract.edit.device_type.label')" />
              </div>
              <div>
                {{ getTranslationForDeviceType(contract.category_code) }}
              </div>
            </div>
            <div>
              <div class="font-semibold">
                <n-html :text="$t('form.contract.edit.manufacturer.label')" />
              </div>
              <div>{{ form.manufacturer }}</div>
            </div>
          </div>
          <div class="border-b pb-1 pt-3 leading-8 font-serif grid grid-cols-2">
            <div>
              <div class="font-semibold">
                <n-html :text="$t('form.contract.edit.device_name.label')" />
              </div>
              <div>{{ form.device_name }}</div>
            </div>
            <div>
              <div class="font-semibold">
                <n-html :text="$t('form.contract.edit.purchase_date.label')" />
              </div>
              <div>
                {{ this.format_date(form.purchase_date, "DD-MM-YYYY") }}
              </div>
            </div>
          </div>
          <div class="border-b pb-1 pt-3 leading-8 font-serif grid grid-cols-2">
            <div>
              <div class="font-semibold">
                <n-html :text="$t('form.contract.edit.object_value.label')" />
              </div>
              <div>{{ form.object_value }}</div>
            </div>
            <div>
              <div class="font-semibold">
                {{ getIdentifierLabel() }}
              </div>
              <div>{{ getIdentifier() }}</div>
            </div>
          </div>
          <div class="border-b pb-1 pt-3 leading-8 font-serif grid grid-cols-2">
            <div>
              <div class="font-semibold">
                <n-html
                  :text="$t('form.contract.edit.store_of_purchase.label')"
                />
              </div>
              <div>{{ form.store_of_purchase }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="step === 4">
        <div>
          <h2 class="pb-2 text-brand-purple-500 font-semibold text-lg border-b">
            <n-html :text="$t('insurances.edit_modal.password.title')" />
          </h2>
          <div class="pb-1 pt-3 leading-8 font-serif grid grid-cols-2 gap-x-4">
            <div>
              <div class="font-semibold">
                <n-html :text="$t('form.password_check.label')" />
              </div>
              <n-form-group
                id="input-group-password"
                :invalid-feedback="$t('form.password_check.feedback.invalid')"
                :state="form.password"
              >
                <n-form-input-default
                  id="input-password"
                  :placeholder="$t('form.password_check.placeholder')"
                  v-model="form.password"
                  field_type="password"
                  type="password"
                />
              </n-form-group>
            </div>
            <div>
              <div class="font-semibold">
                <n-html :text="$t('form.password_repeat_check.label')" />
              </div>
              <n-form-group
                id="input-group-repeat-password"
                :invalid-feedback="
                  $t('form.password_repeat_check.feedback.invalid')
                "
                label-for="input-repeat-password"
                :state="form.repeat_password"
              >
                <n-form-input-password
                  id="input-repeat-password"
                  :placeholder="$t('form.password_repeat_check.placeholder')"
                  v-model="form.repeat_password"
                />
              </n-form-group>
            </div>
          </div>
          <div class="pt-10">
            <h2
              class="pb-2 text-brand-purple-500 font-semibold text-lg border-b"
            >
              <n-html
                :text="$t('insurances.edit_modal.password.are_you_sure')"
              />
            </h2>
            <div>
              <n-checkbox
                class="pt-4"
                v-model="form.has_agreed"
                :label="$t('insurances.edit_modal.confirm_edit')"
              />
              <n-checkbox
                v-model="form.has_agreed_product"
                :label="$t('insurances.edit_modal.confirm_product')"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="step === 5">
        <p class="pb-4 text-sm">
          <n-html :text="$t('insurances.edit_modal.success.message_new')" />
        </p>
        <CardProductSmall :contract="contract" dense :useBorder="true" />
      </div>
    </div>
    {{ /* Footer */ }}
    <template v-slot:footer>
      <n-button-link bold @click.native="close" v-if="step === 1">
        <n-html
          :text="$t('insurances.cancel_modal.preview.buttons.back_to_overview')"
        />
      </n-button-link>
      <n-button-link bold @click.native="previousStep" v-else-if="step < 5">
        <n-html :text="$t('insurances.cancel_modal.buttons.previous')" />
      </n-button-link>
      <n-button-regular
        class="sm:w-auto w-full"
        @click.native="nextStep"
        :disabled="!stepIsValid"
        v-if="step < 4"
      >
        <n-html :text="$t('insurances.cancel_modal.buttons.next')" />
      </n-button-regular>
      <n-button-regular
        @click.native="submit"
        :disabled="!stepIsValid"
        v-else-if="step === 4"
      >
        <n-html :text="$t('insurances.edit_modal.password.buttons.change')" />
      </n-button-regular>
      <div class="ml-auto" v-else>
        <n-button-regular @click.native="close">
          <n-html :text="$t('insurances.cancel_modal.success.buttons.back')" />
        </n-button-regular>
      </div>
    </template>
  </modal>
</template>

<script>
import CardProductSmall from "./../../components/products/card-product-small";
import format from "../../mixins/format";
import moment from "moment";
import { mapGetters } from "vuex";
import InsuranceImageUpload from "./insurance-image-upload.vue";
import Modal from "@/components/theme/modal/modal";

export default {
  name: "components.insurances.modal-insurance-cancel",
  components: { CardProductSmall, InsuranceImageUpload, Modal },
  mixin: [format],
  props: {
    value: Boolean,
    contract: Object,
    show: Boolean,
    onCloseModal: Function
  },
  computed: {
    ...mapGetters({
      manufacturers: "insurance/getManufacturers"
    }),
    mobile() {
      return this.isMobile(window.innerWidth);
    },
    manufacturer_options() {
      let options = [];

      // options.push({
      //   value: null,
      //   key: this.$t("form.contract.edit.choose_manufacturer")
      // });

      this.manufacturers.forEach(element => {
        options.push({
          code: element.code,
          label: element.name
        });
      });

      return options;
    },
    minDate() {
      return moment()
        .subtract(1, "years")
        .toDate();
    },
    maxDate() {
      return moment().toDate();
    },
    stepIsValid() {
      if (this.step === 1) {
        return this.contract.late_payment_amount <= 0;
      } else if (this.step === 2) {
        var number_check = false;
        if (this.contract.category_code === "PHONE") {
          number_check = this.form.imei && this.validIMEI(this.form.imei);
        } else {
          number_check =
            this.form.serial_number && this.form.serial_number.length >= 10;
        }

        return (
          number_check &&
          this.form.manufacturer &&
          this.form.device_name &&
          this.form.purchase_date &&
          this.form.object_value &&
          this.form.store_of_purchase &&
          this.form.pictures.device.length > 0 &&
          this.form.pictures.receipt.length > 0
        );
      } else if (this.step === 4) {
        return (
          this.form.password === this.form.repeat_password &&
          this.form.has_agreed &&
          this.form.has_agreed_product
        );
      } else {
        return true;
      }
    },
    getTitle() {
      if (this.step === 5) {
        return this.$t("insurances.edit_modal.success.title");
      }
      return this.$t("insurances.edit_modal.title");
    },
    deviceTypes() {
      return [
        {
          value: this.contract.category_code,
          key: this.getTranslationForDeviceType(this.contract.category_code)
        }
      ];
    }
  },
  data: () => ({
    step: 1,
    form: {
      manufacturer: null,
      device_name: "",
      purchase_date: "",
      object_value: "",
      imei: "",
      serial_number: "",
      store_of_purchase: "",
      article_no: "",
      password: "",
      repeat_password: "",
      has_agreed: false,
      has_agreed_product: false,
      pictures: {
        device: [],
        receipt: []
      }
    },
    errors: {
      imei: null
    },
    types: ["PHONE", "TABLET"]
  }),
  methods: {
    nextStep() {
      this.step += 1;
    },
    previousStep() {
      this.step -= 1;
    },
    isInt(n) {
      return n % 1 === 0;
    },
    validIMEI(imei) {
      imei = imei.split("");
      let total = 0;

      if (imei.length !== 15) {
        return false;
      }

      for (let i = 0; i < imei.length; i++) {
        if (i % 2 === 0) {
          // If even number
          total += parseInt(imei[i]);
          continue;
        }

        let calculated = parseInt(imei[i]) * 2;

        if (calculated > 9) {
          // If greater than 9
          let splitted = String(calculated).split("");
          total += parseInt(splitted[0]) + parseInt(splitted[1]);
        } else {
          total += calculated;
        }
      }
      this.errors.imei = !!this.isInt(total / 10);
      return !!this.isInt(total / 10);
    },
    getTranslationForDeviceType(code) {
      return code.charAt(0) + code.slice(1).toLowerCase();
    },
    getIdentifier() {
      if (this.form.imei) {
        return this.form.imei;
      }
      return this.form.serial_number;
    },
    getIdentifierLabel() {
      if (this.form.imei) {
        return this.$t("form.contract.edit.imei.label");
      }
      return this.$t("form.contract.edit.serial_number.label");
    },
    onImageUpload(data) {
      this.form.pictures.device = data.device_pictures;
      this.form.pictures.receipt = data.receipt_pictures;
    },
    close() {
      this.step = 1;
      this.form.manufacturer = null;
      this.form.device_name = "";
      this.form.purchase_date = "";
      this.form.object_value = "";
      this.form.imei = "";
      this.form.serial_number = "";
      this.form.store_of_purchase = "";
      this.form.article_no = "";
      this.form.password = "";
      this.form.has_agreed = false;
      this.form.has_agreed_product = false;
      this.pictures = {
        device: [],
        receipt: []
      };
      this.onCloseModal();
    },
    onManufacturerChange(value) {
      this.form.manufacturer = value;
    },
    submit() {
      const payload = {
        itemCategoryCode: this.contract.category_code,
        collectionItemNo: this.form.article_no,
        manufacturer: this.form.manufacturer,
        description: this.form.device_name,
        purchaseDate: this.form.purchase_date,
        objectValue: this.form.object_value,
        iMEI: this.form.imei,
        serialNo: this.form.serial_number,
        storeOfPurchase: this.form.store_of_purchase
      };

      let formData = new FormData();
      this.form.pictures.device.forEach((f, i) => {
        formData.append(`file-device-${i}`, f);
      });
      this.form.pictures.receipt.forEach((f, i) => {
        formData.append(`file-receipt-${i}`, f);
      });

      formData.append("data", JSON.stringify(payload));

      this.$store
        .dispatch("contracts/change", {
          id: this.contract.id,
          payload: formData
        })
        .then(result => {
          console.log(result);
          this.step = 5;
        })
        .catch(error => {
          this.errors = error.response.data;
        })
        .finally(() => this.$store.dispatch("contracts/getAll"));
      this.step = 5;
    }
  },
  watch: {
    step(n) {
      if (n) {
        if (n === 2) {
          const params = {
            template: null,
            contract_amount: 1,
            item_category: this.contract.category_code
          };
          this.$store.dispatch("insurance/getManufacturers", params);
        }
      }
    },
    value(val) {
      this.$emit("input", val);
      if (val === false) {
        setTimeout(() => (this.step = 1), 200);
      }
    }
  }
};
</script>
