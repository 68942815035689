<template>
  <Accordion class="insurance-card p-0" no-padding>
    <accordion-header
      :collapsed="internal_collapsed"
      @click.native="internal_collapsed = !internal_collapsed"
    >
      <div class="flex flex-col lg:flex-row font-serif">
        <h1
          :class="[
            active ? 'text-brand-purple-500' : '',
            'text-lg font-medium leading-6 mr-5'
          ]"
        >
          {{ contract.contract_description }}
        </h1>
        <div class="text-sm font-light text-brand-gray-500">
          {{ contract.description }}
        </div>
      </div>
    </accordion-header>
    <accordion-body
      :collapsed="internal_collapsed"
      class="flex flex-col lg:flex-row"
    >
      <div class="w-fit mx-auto lg:mr-4">
        <n-icon :name="contract.category" />
      </div>
      <div class="w-full">
        <div class="border-b lg:border-t grid grid-cols-4 gap-4 py-4">
          <insurance-detail-item
            class="col-span-2"
            :title="$t('insurances.form.starting_date')"
            :value="this.format_date(contract.starting_date, 'DD-MM-YYYY')"
          />
          <insurance-detail-item
            class="col-span-2"
            :title="$t('insurances.form.expiration_date')"
            :value="
              contract.calculated_ending_date === '0001-01-01' ||
              contract.calculated_ending_date == null
                ? '-'
                : this.format_date(
                    contract.calculated_ending_date,
                    'DD-MM-YYYY'
                  )
            "
          />
          <insurance-detail-item
            class="col-span-2"
            :title="$t('insurances.form.claims')"
            :value="`${contract.claims_count}`"
          />
          <insurance-detail-item
            class="col-span-2"
            :title="$t('insurances.form.object_value')"
            :value="
              contract.object_value
                ? '€ ' + contract.object_value.toFixed(2)
                : '-'
            "
          />
        </div>

        <div class="border-b grid grid-cols-4 gap-4 py-4">
          <insurance-detail-item
            class="col-span-2"
            :title="$t('insurances.form.policy_number')"
            :value="contract.navision_ext_id"
          />
          <insurance-detail-item
            class="col-span-2"
            :title="$t('insurances.form.closed_via')"
            :value="contract.store"
          />
          <insurance-detail-item
            class="col-span-2"
            :title="$t('insurances.form.imei')"
            :value="contract.imei"
          />
          <insurance-detail-item
            class="col-span-2"
            :title="$t('insurances.form.serial_number')"
            :value="contract.serial_number"
          />
        </div>
        <div
          class="pb-1 pt-6 leading-8 font-serif flex flex-col lg:flex-row justify-between"
        >
          <div class="grid grid-cols-1">
            <div class="flex mr-5">
              <n-icon name="Pdf" class="mr-2" small />
              <n-button-link
                @click.native="downloadPolicyConditions(contract.id, 'ipid')"
              >
                <n-html :text="$t('insurances.actions.download.ipid')" />
              </n-button-link>
            </div>
            <div class="flex mr-5">
              <n-icon name="Pdf" class="mr-2" small />
              <n-button-link
                @click.native="
                  downloadPolicyConditions(contract.id, 'policy_sheet')
                "
              >
                <n-html
                  :text="$t('insurances.actions.download.policy_sheet')"
                />
              </n-button-link>
            </div>
            <div class="flex mr-5">
              <n-icon name="Pdf" class="mr-2" small />
              <n-button-link
                @click.native="
                  downloadPolicyConditions(contract.id, 'policy_conditions')
                "
              >
                <n-html
                  :text="$t('insurances.actions.download.policy_conditions')"
                />
              </n-button-link>
            </div>
          </div>
          <div class="flex mt-2 md:items-end">
            <div
              v-if="active"
              class="flex w-full flex-row-reverse justify-items-end md:flex-row gap-6 md:gap-4"
            >
              <n-button-regular
                class="w-full float:left sm:w-auto"
                @click.native="showEditModal(true)"
                secondary
              >
                <n-html :text="$t('insurances.actions.edit')" />
              </n-button-regular>
              <n-button-regular secondary @click.native="showCancelModal(true)">
                <n-html :text="$t('insurances.actions.cancel')" />
              </n-button-regular>
            </div>
          </div>
        </div>
        <div v-if="this.hasDocumentError" class="text-brand-red-500">
          <n-html :text="$t(this.errorKey)"></n-html>
        </div>
      </div>
    </accordion-body>
    <modal-insurance-edit
      :contract="contract"
      :show="edit_modal_visible"
      :onCloseModal="() => showEditModal(false)"
    />
    <modal-insurance-cancel
      :contract="contract"
      :show="cancel_modal_visible"
      :onCloseModal="() => showCancelModal(false)"
      :openEditModal="
        () => {
          showCancelModal(false);
          showEditModal(true);
        }
      "
    />
  </Accordion>
</template>

<script>
import ModalInsuranceCancel from "./modal-insurance-cancel";
import ModalInsuranceEdit from "./modal-insurance-edit";
import format from "../../mixins/format";
import { downloadFile } from "@/utils";
import AccordionHeader from "@/components/theme/accordion/accordion-header";
import Accordion from "@/components/theme/accordion/accordion";
import AccordionBody from "@/components/theme/accordion/accordion-body";
import InsuranceDetailItem from "@/components/insurances/details/insurance-detail-item";

export default {
  name: "components.insurances.card-insurance-details",
  components: {
    InsuranceDetailItem,
    AccordionBody,
    Accordion,
    AccordionHeader,
    ModalInsuranceEdit,
    ModalInsuranceCancel
  },
  mixin: [format],
  data: () => ({
    internal_collapsed: true,
    cancel_modal_visible: false,
    edit_modal_visible: false,
    errorKey: ""
  }),
  computed: {
    hasDocumentError() {
      return this.errorKey !== undefined && this.errorKey !== ""
    }
  },
  props: {
    contract: Object,
    collapsed: Boolean,
    active: Boolean
  },
  methods: {
    downloadPolicyConditions(id, file_type) {
      this.$store
        .dispatch("contracts/get_file", { id, file_type })
        .then(response => {
          downloadFile(response.data.dataUri, response.data.filename);
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) { // file wasn't found
            this.errorKey = `insurances.actions.${file_type}_not_found`
            return;
          }
          // throw the error it the error was not a 404
          throw err;
        });
    },
    showCancelModal(value) {
      this.cancel_modal_visible = value;
      this.$gtag.event("Step 1: ContractOpenCancelModal", {
        contract_id: this.contract.navision_ext_id
      });
    },
    showEditModal(value) {
      this.edit_modal_visible = value;
    }
  },
  mounted() {
    this.internal_collapsed = this.collapsed;
  }
};
</script>

<style>
.insurance-card .card-body {
  padding: 0;
}

.insurance-card .card-body .insurance-card-title {
  cursor: pointer;
}

.insurance-card .card-body .insurance-card-title .insurance-card-title-icon {
  right: 20px;
  bottom: 25px;
  position: absolute;
}

.insurance-card-footer-left div {
  float: left;
}

.mobile .insurance-card-footer-left {
  display: grid;
  margin-bottom: 10px;
}

.insurance-card-footer-right {
  float: right;
  position: relative;
}

.insurance-card-footer-right a {
  margin-left: 15px;
}

.mobile .insurance-card-footer-right {
  float: left;
}
</style>
