var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Accordion',{staticClass:"insurance-card p-0",attrs:{"no-padding":""}},[_c('accordion-header',{attrs:{"collapsed":_vm.internal_collapsed},nativeOn:{"click":function($event){_vm.internal_collapsed = !_vm.internal_collapsed}}},[_c('div',{staticClass:"flex flex-col lg:flex-row font-serif"},[_c('h1',{class:[
          _vm.active ? 'text-brand-purple-500' : '',
          'text-lg font-medium leading-6 mr-5'
        ]},[_vm._v(" "+_vm._s(_vm.contract.contract_description)+" ")]),_c('div',{staticClass:"text-sm font-light text-brand-gray-500"},[_vm._v(" Polisnummer: "+_vm._s(_vm.contract.navision_ext_id)+" ")])])]),_c('accordion-body',{staticClass:"flex flex-col lg:flex-row",attrs:{"collapsed":_vm.internal_collapsed}},[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"grid grid-cols-2 lg:grid-cols-6 gap-4"},[_c('insurance-detail-item',{attrs:{"title":_vm.$t('insurances.form.policy_number'),"value":_vm.contract.navision_ext_id}}),_c('insurance-detail-item',{attrs:{"title":_vm.$t('insurances.form.starting_date'),"value":this.format_date(_vm.contract.starting_date, 'DD-MM-YYYY')}}),_c('insurance-detail-item',{attrs:{"title":_vm.$t('insurances.form.expiration_date'),"value":_vm.contract.calculated_ending_date === '0001-01-01' ||
            _vm.contract.calculated_ending_date == null
              ? '-'
              : this.format_date(
                  _vm.contract.calculated_ending_date,
                  'DD-MM-YYYY'
                )}}),_c('insurance-detail-item',{attrs:{"title":_vm.$t('insurances.form.closed_via'),"value":_vm.contract.store}}),_c('insurance-detail-item',{attrs:{"title":_vm.$t('insurances.form.claims'),"value":("" + (_vm.contract.claims_count))}}),_c('insurance-detail-item',{attrs:{"title":_vm.$t('insurances.form.multidevice'),"value":_vm.contract.multidevice ? _vm.$t('common.yes') : _vm.$t('common.no')}})],1),_c('div',{staticClass:"pb-1 pt-6 leading-8 font-serif flex flex-wrap justify-between"},[_c('div',{staticClass:"flex border-b border-t w-full flex-col col-span-12 py-3 lg:border-b-0 lg:border-t-0 lg:flex-row w-auto"},[_c('div',{staticClass:"flex items-center mr-5"},[_c('n-icon',{staticClass:"mr-2",attrs:{"name":"Pdf","small":""}}),_c('n-button-link',{nativeOn:{"click":function($event){return _vm.downloadPolicyConditions(_vm.contract.id, 'ipid')}}},[_c('n-html',{attrs:{"text":_vm.$t('insurances.actions.download.ipid')}})],1)],1),_c('div',{staticClass:"flex items-center mr-5"},[_c('n-icon',{staticClass:"mr-2",attrs:{"name":"Pdf","small":""}}),_c('n-button-link',{nativeOn:{"click":function($event){return _vm.downloadPolicyConditions(_vm.contract.id, 'policy_sheet')}}},[_c('n-html',{attrs:{"text":_vm.$t('insurances.actions.download.policy_sheet')}})],1)],1),_c('div',{staticClass:"flex items-center mr-5"},[_c('n-icon',{staticClass:"mr-2",attrs:{"name":"Pdf","small":""}}),_c('n-button-link',{nativeOn:{"click":function($event){return _vm.downloadPolicyConditions(_vm.contract.id, 'policy_conditions')}}},[_c('n-html',{attrs:{"text":_vm.$t('insurances.actions.download.policy_conditions')}})],1)],1)]),_c('div',{staticClass:"flex mt-5 items-center lg:mt-0"},[(_vm.active)?_c('div',{staticClass:"flex flex-wrap items-center gap-6 md:gap-4"},[_c('n-button-regular',{staticClass:"float:left",attrs:{"secondary":"","disabled":_vm.contract.product_set.length >= 5,"title":_vm.contract.product_set.length >= 5
                  ? 'Maximaal aantal producten bereikt.'
                  : ''},nativeOn:{"click":function($event){return _vm.showAddModal(true)}}},[_c('n-html',{attrs:{"text":_vm.$t('insurances.actions.add')}})],1),_c('n-button-regular',{attrs:{"secondary":""},nativeOn:{"click":function($event){return _vm.showCancelModal(true)}}},[_c('n-html',{attrs:{"text":_vm.$t('insurances.actions.cancel')}})],1)],1):_vm._e()])]),(_vm.contract.product_set)?_c('div',{staticClass:"w-full mt-5"},_vm._l((_vm.contract.product_set),function(product,index){return _c('div',{key:index},[_c('div',{staticClass:"flex bg-purple-50 px-6 py-2 -mx-6 mt-6"},[_c('span',{staticClass:"uppercase text-xs font-semibold"},[_vm._v(_vm._s(product.description))]),_c('div',{staticClass:"ml-auto cursor-pointer",attrs:{"xs":""}},[_c('n-button-link',{attrs:{"variant":"cancel"},nativeOn:{"click":function($event){return (function () {
                    _vm.product_to_cancel = product;
                    _vm.showProductDeleteModal(true);
                  }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("insurances.actions.remove_product"))+" ")])],1)]),_c('div',{staticClass:"grid grid-cols-2 lg:grid-cols-6 gap-4 mt-6"},[_c('n-icon',{staticClass:"hidden lg:block text-black",attrs:{"name":product.category_code}}),_c('insurance-detail-item',{attrs:{"title":_vm.$t('insurances.form.serial_number'),"value":product.serial_number}}),_c('insurance-detail-item',{attrs:{"title":_vm.$t('insurances.form.imei'),"value":product.imei ? product.imei : '-'}}),_c('insurance-detail-item',{attrs:{"title":_vm.$t('insurances.form.object_value'),"value":product.object_value
                  ? '€ ' + product.object_value.toFixed(2)
                  : '-'}}),_c('insurance-detail-item',{attrs:{"title":_vm.$t('insurances.form.manufacturer'),"value":product.manufacturer ? product.manufacturer : '-'}})],1)])}),0):_vm._e()])]),(_vm.contract.multidevice)?_c('modal-insurance-add',{attrs:{"contract":_vm.contract,"show":_vm.add_modal_visible,"onCloseModal":function () {
        _vm.showAddModal(false);
        this$1.$forceUpdate();
      }}}):_vm._e(),_c('modal-insurance-edit',{attrs:{"contract":_vm.contract,"show":_vm.edit_modal_visible,"onCloseModal":function () { return _vm.showEditModal(false); }}}),_c('modal-insurance-cancel',{attrs:{"contract":_vm.contract,"show":_vm.cancel_modal_visible,"onCloseModal":function () { return _vm.showCancelModal(false); }}}),_c('modal-insurance-cancel-product',{attrs:{"contract-id":_vm.contract.id,"product":_vm.product_to_cancel,"show":_vm.delete_product_modal_visible},on:{"onCloseModal":function () {
        this$1.showProductDeleteModal(false);
        this$1.product_to_cancel = null;
      }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }