<template>
  <modal
    :class="show ? '' : 'hidden'"
    v-if="contract != null"
    @close="close()"
    :title="getTitle"
    :small="mobile"
  >
    {{ /* Steps */ }}
    <div class="sm:px-12 px-0 py-5">
      <div v-if="step === 1">
        <CardProductSmall :contract="contract" dense :useBorder="true" />
        <div>
          <h2
            class="mt-4 pb-2 sm:text-brand-purple-500 text-black font-semibold sm:text-lg text-sm border-b"
          >
            <n-html :text="$t('insurances.add_modal.description.form.title')" />
          </h2>
          <div class="flex flex-col gap-y-3 pt-4">
            <div>
              <p class="pb-1 text-sm font-semibold">
                <n-html :text="$t('form.contract.add.device_type.label')" />
              </p>
              <n-select
                :value="form.device_type"
                :onchange="onDeviceTypeChange"
                :options="deviceTypes"
                border
              />
            </div>
            <div class="mt-2">
              <p class="pb-1 text-sm font-semibold">
                <n-html :text="$t('form.contract.add.manufacturer.label')" />
              </p>
              <n-select-search
                :value="this.form.manufacturer"
                :placeholder="$t('form.contract.add.manufacturer.placeholder')"
                :oninput="onManufacturerChange"
                :options="manufacturer_options"
              />
            </div>
            <div class="mt-2">
              <p class="pb-1 text-sm font-semibold">
                <n-html :text="$t('form.contract.add.device_name.label')" />
              </p>
              <n-form-group id="input-group-device_name">
                <n-form-input-default
                  id="input-device_name"
                  :placeholder="$t('form.contract.add.device_name.placeholder')"
                  v-model="form.device_name"
                  field_type="text"
                  maxlength="80"
                />
              </n-form-group>
            </div>
            <div class="mt-2">
              <p class="pb-1 text-sm font-semibold">
                <n-html :text="$t('form.contract.add.purchase_date.label')" />
              </p>
              <n-form-group id="input-group-purchase_date">
                <n-form-input-default
                  id="input-purchase_date"
                  class="w-full"
                  :placeholder="
                    $t('form.contract.add.purchase_date.placeholder')
                  "
                  v-model="form.purchase_date"
                  field_type="date"
                  :minDate="minDate"
                  :maxDate="maxDate"
                  :auto-apply="true"
                />
              </n-form-group>
            </div>
            <div class="mt-2">
              <label class="pb-1 text-sm font-semibold">
                <n-html :text="$t('form.contract.add.object_value.label')" />
              </label>
              <n-form-group id="input-group-object_value">
                <n-form-input-default
                  id="input-object_value"
                  v-model="form.object_value"
                  :max="maxInsuredValue"
                  :min="minInsuredValue"
                  :state="this.errors.object_value"
                  type="number"
                  :placeholder="
                    $t('form.contract.add.object_value.placeholder')
                  "
                />
                <span
                  class="text-brand-red-500"
                  v-if="!this.errors.object_value"
                >
                  {{ $t("form.contract.add.object_value.error") }} <br />
                  <small
                    >Min: &euro;{{ this.minInsuredValue }} Max: &euro;{{
                      this.maxInsuredValue
                    }}</small
                  >
                </span>
              </n-form-group>
            </div>
            <div v-if="form.device_type === 'PHONE'" class="mt-2">
              <p class="pb-1 text-sm font-semibold">
                <n-html :text="$t('form.contract.add.imei.label')" />
              </p>
              <n-form-group id="input-group-imei">
                <n-form-input-default
                  id="input-imei"
                  :placeholder="$t('form.contract.add.imei.placeholder')"
                  v-model="form.imei"
                  field_type="text"
                  maxlength="15"
                  :state="this.errors.imei"
                />
              </n-form-group>
              <span class="text-sm">
                <n-html :text="$t('form.contract.add.imei.subtitle')" />
              </span>
            </div>
            <div v-else class="mt-2">
              <label class="pb-1 text-sm font-semibold">
                <n-html :text="$t('form.contract.add.serial_number.label')" />
              </label>
              <n-form-group id="input-group-serial_number">
                <n-form-input-default
                  id="input-serial_number"
                  :placeholder="
                    $t('form.contract.add.serial_number.placeholder')
                  "
                  v-model="form.serial_number"
                  field_type="text"
                  maxlength="30"
                />
              </n-form-group>
              <span class="text-sm">
                <n-html
                  :text="$t('form.contract.add.serial_number.subtitle')"
                />
              </span>
            </div>
            <div class="mt-6">
              <h2
                class="mt-4 pb-2 text-brand-purple-500 font-semibold text-lg border-b"
              >
                <n-html :text="$t('form.contract.add.picture.label')" />
              </h2>
              <n-form-group id="input-group-picture" class="mt-4">
                <insurance-receipt-upload
                  @imageSelected="d => this.onImageUpload(d)"
                />
              </n-form-group>
            </div>
          </div>
        </div>
      </div>
      <div v-if="step === 2">
        <CardProductSmall :contract="contract" dense :useBorder="true" />
        <div>
          <h2
            class="mt-4 pb-2 text-brand-purple-500 font-semibold text-lg border-b"
          >
            <n-html :text="$t('insurances.add_modal.review.title')" />
          </h2>
          <div class="border-b pb-1 pt-3 leading-8 font-serif grid grid-cols-2">
            <div>
              <div class="font-semibold">
                <n-html
                  :text="$t('insurances.add_modal.review.form.insurance')"
                />
              </div>
              <div>{{ contract.contract_description }}</div>
            </div>
            <div>
              <div class="font-semibold">
                <n-html
                  :text="$t('insurances.cancel_modal.review.form.product')"
                />
              </div>
              <div>{{ form.device_name }}</div>
            </div>
          </div>
          <div class="border-b pb-1 pt-3 leading-8 font-serif grid grid-cols-2">
            <div>
              <div class="font-semibold">
                <n-html :text="$t('form.contract.add.device_type.label')" />
              </div>
              <div>
                {{ getTranslationForDeviceType(form.device_type) }}
              </div>
            </div>
            <div>
              <div class="font-semibold">
                <n-html :text="$t('form.contract.add.manufacturer.label')" />
              </div>
              <div>{{ form.manufacturer }}</div>
            </div>
          </div>
          <div class="border-b pb-1 pt-3 leading-8 font-serif grid grid-cols-2">
            <div>
              <div class="font-semibold">
                <n-html :text="$t('form.contract.add.device_name.label')" />
              </div>
              <div>{{ form.device_name }}</div>
            </div>
            <div>
              <div class="font-semibold">
                <n-html :text="$t('form.contract.add.purchase_date.label')" />
              </div>
              <div>
                {{ this.format_date(form.purchase_date, "DD-MM-YYYY") }}
              </div>
            </div>
          </div>
          <div class="border-b pb-1 pt-3 leading-8 font-serif grid grid-cols-2">
            <div>
              <div class="font-semibold">
                <n-html :text="$t('form.contract.add.object_value.label')" />
              </div>
              <div>{{ form.object_value }}</div>
            </div>
            <div>
              <div class="font-semibold">
                {{ getIdentifierLabel() }}
              </div>
              <div>{{ getIdentifier() }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="step === 3">
        <div class="pt-10">
          <h2 class="pb-2 text-brand-purple-500 font-semibold text-lg border-b">
            <n-html :text="$t('insurances.add_modal.are_you_sure')" />
          </h2>
          <div>
            <n-checkbox
              class="pt-4"
              v-model="form.has_agreed"
              :label="$t('insurances.add_modal.confirm_add')"
            />
            <n-checkbox
              v-model="form.has_agreed_product"
              :label="$t('insurances.add_modal.confirm_product')"
            />
          </div>
        </div>
      </div>
    </div>
    {{ /* Footer */ }}
    <template v-slot:footer>
      <div
        class="flex flex-col flex-col-reverse w-full items-center gap-3 lg:flex-row lg:justify-between"
      >
        <n-button-link bold @click.native="close" v-if="step === 1">
          <n-html
            :text="
              $t('insurances.cancel_modal.preview.buttons.back_to_overview')
            "
          />
        </n-button-link>
        <n-button-link bold @click.native="previousStep" v-else-if="step < 5">
          <n-html :text="$t('insurances.cancel_modal.buttons.previous')" />
        </n-button-link>
        <n-button-regular
          class="lg:w-auto w-full"
          @click.native="nextStep"
          :disabled="!stepIsValid"
          v-if="step < 3"
        >
          <n-html :text="$t('insurances.cancel_modal.buttons.next')" />
        </n-button-regular>
        <n-button-regular
          @click.native="submit"
          :disabled="!stepIsValid"
          v-else-if="step === 3"
        >
          <n-html :text="$t('insurances.add_modal.buttons.submit')" />
        </n-button-regular>
        <div class="ml-auto" v-else>
          <n-button-regular @click.native="close">
            <n-html
              :text="$t('insurances.cancel_modal.success.buttons.back')"
            />
          </n-button-regular>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import CardProductSmall from "./../../components/products/card-product-small";
import format from "../../mixins/format";
import moment from "moment";
import { mapGetters } from "vuex";
import Modal from "@/components/theme/modal/modal";
import InsuranceReceiptUpload from "@/components/insurances/insurance-receipt-upload";

export default {
  name: "components.insurances.modal-insurance-add",
  components: { CardProductSmall, InsuranceReceiptUpload, Modal },
  mixin: [format],
  props: {
    value: Boolean,
    contract: Object,
    show: Boolean,
    onCloseModal: Function
  },
  computed: {
    ...mapGetters({
      manufacturers: "insurance/getManufacturers"
    }),
    mobile() {
      return this.isMobile(window.innerWidth);
    },
    manufacturer_options() {
      let options = [];
      this.manufacturers.forEach(element => {
        options.push({
          code: element.code,
          label: element.name
        });
      });
      return options;
    },
    minDate() {
      return moment()
        .subtract(1, "years")
        .toDate();
    },
    maxDate() {
      return moment().toDate();
    },
    stepIsValid() {
      switch (this.step) {
        case 1:
          if (
            !this.form.device_type ||
            !this.form.manufacturer ||
            !this.form.device_name ||
            !this.form.purchase_date
          ) {
            return false;
          }

          if (
            !this.form.object_value ||
            !this.validObjectValue(this.form.object_value)
          ) {
            return false;
          }

          if (
            this.form.device_type === "PHONE" &&
            (!this.form.imei || !this.validIMEI(this.form.imei))
          ) {
            return false;
          }

          return !(
            this.form.device_type !== "PHONE" &&
            (!this.form.serial_number || this.form.serial_number.length < 10)
          );

        case 3:
          return this.form.has_agreed && this.form.has_agreed_product;
        default:
          return true;
      }
    },
    getTitle() {
      if (this.step === 4) {
        return this.$t("insurances.add_modal.success.title");
      }
      return this.$t("insurances.add_modal.title");
    },
    deviceTypes() {
      let deviceTypes = [
        {
          value: this.contract.category_code,
          key: this.getTranslationForDeviceType(this.contract.category_code)
        }
      ];
      if (this.contract.contractarticlegroup_set) {
        const { contractarticlegroup_set } = this.contract;
        deviceTypes = [
          { value: null, key: "" },
          ...contractarticlegroup_set.map(contractArticleGroup => {
            const { category_code } = contractArticleGroup;
            return {
              value: category_code,
              key: this.getTranslationForDeviceType(category_code)
            };
          })
        ];
      }
      return deviceTypes;
    },
    maxInsuredValue() {
      if (this.contract.contractarticlegroup_set) {
        let item = this.contract.contractarticlegroup_set.find(
          x => x.category_code === this.form.device_type
        );
        if (item) {
          return item.max_insured_value;
        }
      }
      return 10000;
    },
    minInsuredValue() {
      if (this.contract.contractarticlegroup_set) {
        var item = this.contract.contractarticlegroup_set.find(
          x => x.category_code === this.form.device_type
        );
        if (item) {
          return item.min_insured_value;
        }
      }
      return 0;
    }
  },
  data: () => ({
    step: 1,
    form: {
      device_type: null,
      manufacturer: null,
      device_name: "",
      purchase_date: "",
      object_value: "",
      imei: "",
      serial_number: "",
      has_agreed: false,
      has_agreed_product: false,
      pictures: {
        receipt: []
      }
    },
    errors: {
      imei: null,
      object_value: null
    },
    types: ["PHONE", "TABLET"]
  }),
  methods: {
    nextStep() {
      this.step += 1;
    },
    previousStep() {
      this.step -= 1;
    },
    isInt(n) {
      return n % 1 === 0;
    },
    validObjectValue(object_value) {
      if (object_value === "") {
        return false;
      }

      const isValid =
        object_value >= this.minInsuredValue &&
        object_value <= this.maxInsuredValue;

      if (!isValid) {
        this.errors.object_value = false;
      }

      return isValid;
    },
    validIMEI(imei) {
      imei = imei.split("");
      let total = 0;

      if (imei.length !== 15) {
        return false;
      }

      for (let i = 0; i < imei.length; i++) {
        if (i % 2 === 0) {
          // If even number
          total += parseInt(imei[i]);
          continue;
        }

        let calculated = parseInt(imei[i]) * 2;

        if (calculated > 9) {
          // If greater than 9
          let splitted = String(calculated).split("");
          total += parseInt(splitted[0]) + parseInt(splitted[1]);
        } else {
          total += calculated;
        }
      }
      this.errors.imei = !!this.isInt(total / 10);
      return !!this.isInt(total / 10);
    },
    getTranslationForDeviceType(code) {
      return code.charAt(0) + code.slice(1).toLowerCase();
    },
    getIdentifier() {
      return this.form.imei ? this.form.imei : this.form.serial_number;
    },
    getIdentifierLabel() {
      return this.form.imei
        ? this.$t("form.contract.add.imei.label")
        : this.$t("form.contract.add.serial_number.label");
    },
    onImageUpload(data) {
      this.form.pictures.receipt = data.receipt_pictures;
    },
    close() {
      this.step = 1;
      Object.assign(this.form, {
        device_type: null,
        manufacturer: null,
        device_name: "",
        purchase_date: "",
        object_value: "",
        imei: "",
        serial_number: "",
        has_agreed: false,
        has_agreed_product: false
      });
      this.pictures = { receipt: [] };
      this.onCloseModal();
    },
    getManufacturers(params) {
      return this.$store.dispatch("insurance/getManufacturers", params);
    },
    onManufacturerChange(value) {
      this.form.manufacturer = value;
    },
    // A method that is called when the device type is changed.
    onDeviceTypeChange(e) {
      let value = e.target.value;
      this.form.device_type = value;
      // Setting the manufacturer to null and then calling the getManufacturers function.
      this.form.manufacturer = null;
      this.getManufacturers({
        template: null,
        contract_amount: 1,
        item_category: value
      });
    },
    async submit() {
      const {
        device_type,
        manufacturer,
        device_name,
        purchase_date,
        object_value,
        imei,
        serial_number,
        pictures
      } = this.form;
      const payload = {
        itemCategoryCode: device_type,
        manufacturer,
        description: device_name,
        purchaseDate: purchase_date,
        objectValue: object_value,
        iMEI: imei,
        serialNo: serial_number
      };

      let formData = new FormData();
      pictures.receipt.forEach((f, i) => {
        formData.append(`file-receipt-${i}`, f);
      });

      formData.append("data", JSON.stringify(payload));

      try {
        await this.$store.dispatch("contracts/addProduct", {
          id: this.contract.id,
          payload: formData
        });
        this.step = 3;
      } catch (error) {
        this.errors = error.response.data;
      } finally {
        await this.$store.dispatch("contracts/getAll");
        this.$forceUpdate();
        this.close();
      }
    }
  },
  watch: {
    value(val) {
      this.$emit("input", val);
      if (val === false) {
        setTimeout(() => (this.step = 1), 200);
      }
    },
    form: {
      handler(val) {
        if (this.validObjectValue(val.object_value)) {
          this.errors.object_value = true;
        }
      },
      deep: true
    }
  }
};
</script>
