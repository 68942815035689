<template>
  <div class="p-6 flex cursor-pointer shadow rounded-lg">
    <slot />
    <div class="ml-auto my-auto">
      <n-icon :name="collapsed ? 'Chevron-up' : 'Chevron-down'" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    collapsed: Boolean
  }
};
</script>
