<template>
  <div
    class="padding-top-grid-between padding-left-grid-between padding-right-grid-between padding-bottom-grid-between"
  >
    <div class="grid grid-cols-12 p-2 md:p-8">
      <div class="col-span-12 sm:col-span-8 m-4 mt-6">
        <h1
          class="uppercase text-brand-purple-500 font-serif font-bold text-3xl"
        >
          <n-html :text="$t('insurances.title_active')" />
        </h1>
      </div>

      <div class="col-span-12 sm:col-span-4 m-4 mt-6">
        <n-button-regular
          class="sm:float-right w-full md:w-auto"
          @click.native="$router.push('/insurances/new')"
        >
          <n-html :text="$t('insurances.new_insurance')" />
        </n-button-regular>
      </div>

      <div v-if="activeContracts.length > 0" class="col-span-12">
        <div v-bind:key="index" v-for="(contract, index) in activeContracts">
          <CardInsuranceDetails
            v-if="!contract.multidevice"
            :contract="contract"
            :collapsed="visible(index, contract.id)"
            :active="true"
          />
          <CardInsuranceMultideviceDetails
            v-if="contract.multidevice"
            :contract="contract"
            :collapsed="visible(index, contract.id)"
            :active="true"
          />
        </div>
      </div>

      <!-- Checking if there are any expired contracts. If there are, it will display the expired contracts.-->
      <div v-if="expiredContracts.length > 0" class="col-span-12">
        <div class="col-span-12 m-4 mt-6 border-t pt-8">
          <h1 class="w-full text-lg font-semibold font-serif">
            <n-html :text="$t('insurances.title_expired')" />
          </h1>
        </div>
        <div class="col-span-12">
          <CardInsuranceDetails
            v-for="(contract, index) in expiredContracts"
            v-bind:key="index"
            :contract="contract"
            :collapsed="!visible(index, contract.id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CardInsuranceDetails from "./../../components/insurances/card-insurance-details";
import CardInsuranceMultideviceDetails from "./../../components/insurances/card-insurance-multidevice-details";

export default {
  name: "pages.insurances.index",
  components: {
    CardInsuranceDetails,
    CardInsuranceMultideviceDetails
  },
  computed: {
    ...mapGetters({
      activeContracts: "contracts/getAllActive",
      expiredContracts: "contracts/getAllExpired"
    })
  },
  methods: {
    downloadPolicyConditions(id, file_type) {
      this.$store
        .dispatch("contracts/get_file", { id, file_type })
        .then(response => {
          const downloadLink = document.createElement("a");
          downloadLink.href = response.data.dataUri;
          downloadLink.download = response.data.filename;
          downloadLink.click();
        });
    },
    visible(i, id) {
      if (this.isMobile(window.innerWidth)) {
        return false;
      }
      if ("id" in this.$route.query) {
        return this.$route.query["id"] === id;
      }
      return true;
    }
  }
};
</script>
