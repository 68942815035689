<template>
  <modal
    :class="show ? '' : 'hidden'"
    v-if="product != null"
    @close="close()"
    :title="$t('insurances.modal_cancel_product.title')"
  >
    <div class="p-0 sm:px-12 sm:py-5">
      {{ $t("insurances.modal_cancel_product.are_you_sure") }}
      <div class="py-8">
        {{ product.description }}
      </div>
    </div>
    <template v-slot:footer>
      <div
        class="grid justify-center sm:flex sm:justify-between w-full items-center"
      >
        <n-button-link
          bold
          @click.native="close"
          class="mt-5 sm:mt-0 sm:text-base text-xs order-last sm:order-first"
        >
          <n-html :text="$t('insurances.modal_cancel_product.buttons.back')" />
        </n-button-link>
        <n-button-regular @click.native="submit" :disabled="loading">
          <n-html
            :text="$t('insurances.modal_cancel_product.buttons.close_product')"
          />
        </n-button-regular>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/theme/modal/modal.vue";

export default {
  name: "components.insurances.modal-insurance-cancel-product",
  components: {
    Modal
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object,
      default: null
    },
    contractId: {
      type: String
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    close() {
      this.$emit("onCloseModal");
    },
    submit() {
      this.loading = true;
      this.$axios
        .post(`contracts/${this.contractId}/delete_product/`, {
          data: {
            contractProductID: this.product.ext_contract_product_id,
            lineNo: this.product.ext_line_number
          }
        })
        .then(() => {
          this.$store.dispatch("contracts/getAll");
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
