<template>
  <modal
    :class="show ? '' : 'hidden'"
    v-if="contract != null"
    @close="close()"
    :title="getTitle"
  >
    {{ /* Steps */ }}
    <div class="p-0 sm:px-12 sm:py-5">
      <div v-if="step === 1">
        <p class="text-xs sm:text-sm">
          <n-html :text="$t('insurances.cancel_modal.preview.subtitle')" />
        </p>
        <h2 class="mt-4 text-brand-purple-500 font-semibold text-sm sm:text-lg">
          <n-html
            :text="$t('insurances.cancel_modal.preview.contract_title')"
          />
        </h2>
        <p class="py-4">
          <CardProductSmall :contract="contract" dense :useBorder="true" />
        </p>
        <n-alert variant="primary">
          <div class="flex flex-row font-semibold text-sm">
            <n-icon name="Info" class="mr-2"></n-icon>
            <n-html
              :text="$t('insurances.cancel_modal.preview.balloon.title')"
            />
          </div>
          <div class="text-brand-purple-500 text-xs sm:text-sm mt-2">
            {{
              contract.late_payment_amount > 0
                ? $t("insurances.cancel_modal.preview.balloon.sub_title_failed")
                : $t("insurances.cancel_modal.preview.balloon.sub_title")
            }}
          </div>
        </n-alert>
      </div>
      <div v-if="step === 2">
        <CardProductSmall
          :contract="contract"
          dense
          :useBorder="true"
          class="sm:mt-0 mt-4"
        />
        <div>
          <h2 class="mt-4 pb-2 text-brand-purple-500 font-semibold border-b">
            <n-html
              :text="$t('insurances.cancel_modal.description.end_date.title')"
            />
          </h2>
          <div class="pt-4">
            <p class="pb-1 text-sm mb-1">
              <n-html :text="$t('form.end_date.label')" />
            </p>
            <n-select
              :options="closingCodes"
              :onchange="onClosingCodeChange"
              :value="closing_code"
              border
            >
            </n-select>
          </div>
          <div v-if="ClosingDate">
            <p class="py-4 text-sm">
              {{
                $t("form.end_date.subtitle", {
                  date: this.format_date(ClosingDate, "DD-MM-YYYY")
                })
              }}
            </p>
          </div>
        </div>
        <div class="pb-5" v-if="closing_code">
          <h2 class="mt-4 pb-2 text-brand-purple-500 font-semibold border-b">
            <n-html
              :text="$t('insurances.cancel_modal.description.form.title')"
            />
          </h2>
          <div class="pt-4">
            <div>
              <p class="pb-1 text-sm">
                <n-html :text="$t('form.cancellation_reason.label')" />
              </p>
              <n-select
                :options="closingReasons"
                :onchange="onClosingReasonChange"
                :value="form.reason_option"
                border
              />
            </div>
            <div class="pt-4">
              <div class="flex justify-between">
                <p class="pb-1 text-sm">
                  <n-html :text="$t('form.cancellation_explanation.label')" />
                </p>
                <span
                  :class="
                    `text-xs text-brand-gray-400 ${
                      form.reason_text.length < 1 ? 'text-brand-red-500' : ''
                    }`
                  "
                  >{{ form.reason_text.length }}/249</span
                >
              </div>
              <n-form-group id="input-group-message">
                <n-form-input-default
                  id="input-reason"
                  :placeholder="$t('form.cancellation_explanation.placeholder')"
                  v-model="form.reason_text"
                  field_type="textarea"
                  rows="3"
                  no-resize
                  maxlength="249"
                  required="true"
                />
              </n-form-group>
            </div>
          </div>
        </div>
      </div>
      <div v-if="step === 3">
        <CardProductSmall
          :contract="contract"
          dense
          :useBorder="true"
          class="sm:mt-0 mt-4"
        />
        <div
          v-if="
            this.form.reason_option === 'DUUR' ||
              this.form.reason_option === 'WAARD'
          "
        >
          <h2
            class="mt-4 pb-2 text-brand-purple-500 font-semibold text-base sm:text-lg border-b"
          >
            <n-html
              :text="$t('insurances.cancel_modal.steps.too_expensive.title')"
            />
          </h2>
          <p class="mt-5 pb-5 text-xs sm:text-sm">
            <n-html
              :text="
                $t(
                  'insurances.cancel_modal.steps.too_expensive.consideration.stay_message'
                )
              "
            />
          </p>
          <n-button-regular
            bold
            @click.native="agreeToStayWithDiscount"
            v-if="step === 3"
            class="order-last sm:order-first mt-5 sm:mt-0 flex justify-center sm:text-base text-xs"
          >
            <n-html
              :text="
                $t(
                  'insurances.cancel_modal.steps.too_expensive.consideration.button_text'
                )
              "
            />
          </n-button-regular>
        </div>
        <div v-else-if="this.form.reason_option === 'INBOEDEL'">
          <h2
            class="mt-4 pb-2 text-brand-purple-500 font-semibold text-base sm:text-lg border-b"
          >
            <n-html
              :text="$t('insurances.cancel_modal.steps.home_insurance.title')"
            />
          </h2>
          <ul class="mt-5 benefits-list" style="list-style-type: none;">
            <li class="pt-1 before:content-['\2713\0020']">
              <n-html
                :text="
                  $t(
                    'insurances.cancel_modal.steps.home_insurance.benefits.benefit_one'
                  )
                "
              />
            </li>
            <li class="pt-1 before:content-['\2713\0020']">
              <n-html
                :text="
                  $t(
                    'insurances.cancel_modal.steps.home_insurance.benefits.benefit_two'
                  )
                "
              />
            </li>
            <li class="pt-1 before:content-['\2713\0020']">
              <n-html
                :text="
                  $t(
                    'insurances.cancel_modal.steps.home_insurance.benefits.benefit_three'
                  )
                "
              />
            </li>
          </ul>
          <p class="mt-5">
            <n-button-regular
              bold
              @click.native="close"
              v-if="step === 3"
              class="order-last sm:order-first mt-5 sm:mt-0 flex justify-center sm:text-base text-xs"
            >
              <n-html
                :text="
                  $t(
                    'insurances.cancel_modal.steps.home_insurance.keep_insurance'
                  )
                "
              />
            </n-button-regular>
          </p>
        </div>
        <div v-else-if="this.form.reason_option === 'NIET IN BEZIT'">
          <h2
            class="mt-4 pb-2 text-brand-purple-500 font-semibold text-base sm:text-lg border-b"
          >
            <n-html
              :text="
                $t('insurances.cancel_modal.steps.not_in_possession.title')
              "
            />
          </h2>
          <p class="mt-5">
            <n-button-regular
              bold
              @click.native="openEditModal"
              v-if="step === 3"
              class="order-last sm:order-first mt-5 sm:mt-0 flex justify-center sm:text-base text-xs"
            >
              <n-html
                :text="
                  $t(
                    'insurances.cancel_modal.steps.not_in_possession.accept_edit_insurance'
                  )
                "
              />
            </n-button-regular>
          </p>
        </div>
      </div>
      <div v-if="step === 4">
        <CardProductSmall
          :contract="contract"
          dense
          :useBorder="true"
          class="sm:mt-0 mt-4"
        />
        <div>
          <h2
            class="mt-4 pb-2 text-brand-purple-500 font-semibold text-base sm:text-lg border-b"
          >
            <n-html :text="$t('insurances.cancel_modal.review.title')" />
          </h2>
          <div
            class="border-b pb-1 pt-3 leading-8 font-serif grid grid-cols-1 sm:grid-cols-2"
          >
            <div class="border-b sm:border-b-0 pb-3 sm:pb-0">
              <div class="font-semibold">
                <n-html
                  :text="$t('insurances.cancel_modal.review.form.insurance')"
                />
              </div>
              <div>{{ contract.contract_description }}</div>
            </div>
            <div>
              <div class="font-semibold">
                <n-html
                  :text="$t('insurances.cancel_modal.review.form.product')"
                />
              </div>
              <div>{{ contract.description }}</div>
            </div>
          </div>

          <div
            class="border-b pb-1 pt-3 leading-8 font-serif grid grid-cols-1 sm:grid-cols-2"
          >
            <div class="border-b sm:border-b-0 pb-3 sm:pb-0">
              <div class="font-semibold">
                <n-html
                  :text="$t('insurances.cancel_modal.review.form.ending_date')"
                />
              </div>
              <div>{{ this.format_date(ClosingDate, "DD-MM-YYYY") }}</div>
            </div>
            <div>
              <div class="font-semibold">
                <n-html
                  :text="$t('insurances.cancel_modal.review.form.reason')"
                />
              </div>
              <div>{{ getClosingReasonDescription }}</div>
            </div>
          </div>

          <div
            class="border-b pb-1 pt-3 leading-8 font-serif grid grid-cols-1 sm:grid-cols-2"
          >
            <div>
              <div class="font-semibold">
                <n-html
                  :text="$t('insurances.cancel_modal.review.form.explanation')"
                />
              </div>
              <div>{{ form.reason_text }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="step === 5">
        <div>
          <h2
            class="pb-2 text-brand-purple-500 font-semibold border-b  text-base sm:text-lg"
          >
            <n-html :text="$t('insurances.cancel_modal.password.title')" />
          </h2>
          <div
            class="pb-1 pt-3 leading-8 font-serif grid sm:grid-cols-2 gap-x-4"
          >
            <div class="mb-5 sm:mb-0">
              <div class="font-semibold text-sm sm:text-base">
                <n-html :text="$t('form.password_check.label')" />
              </div>
              <n-form-group
                id="input-group-password"
                :invalid-feedback="$t('form.password_check.feedback.invalid')"
                :state="form.password"
              >
                <n-form-input-password
                  id="input-password"
                  :placeholder="$t('form.password_check.placeholder')"
                  v-model="form.password"
                />
              </n-form-group>
            </div>
            <div>
              <div class="font-semibold text-sm sm:text-base">
                <n-html :text="$t('form.password_repeat_check.label')" />
              </div>
              <n-form-group
                id="input-group-repeat-password"
                :invalid-feedback="
                  $t('form.password_repeat_check.feedback.invalid')
                "
                :state="form.repeat_password"
              >
                <n-form-input-password
                  id="input-repeat-password"
                  :placeholder="$t('form.password_repeat_check.placeholder')"
                  v-model="form.repeat_password"
                />
              </n-form-group>
            </div>
          </div>
          <div class="pt-10">
            <h2
              class="pb-2 text-brand-purple-500 font-semibold border-b text-base sm:text-lg"
            >
              <n-html
                :text="$t('insurances.cancel_modal.password.cancel.title')"
              />
            </h2>
            <n-checkbox
              class="pt-4 text-xs sm:text-sm"
              v-model="form.has_agreed"
              :label="$t('form.cancellation_confirm.label')"
            />
          </div>
          <n-alert v-if="errors.message" class="mt-4" variant="danger">
            <n-html :text="errors.message" />
          </n-alert>
        </div>
      </div>
      <div v-if="step === 6">
        <p class="pb-4 text-xs sm:text-sm">
          {{
            $t("insurances.cancel_modal.success.message_new", {
              date: this.format_date(closing_date, "DD-MM-YYYY")
            })
          }}
        </p>
        <CardProductSmall :contract="contract" dense :useBorder="true" />
      </div>
      <div v-if="step === 7">
        <p class="pb-4 text-xs sm:text-sm">
          <n-html
            :text="
              $t(
                'insurances.cancel_modal.steps.too_expensive.consideration.thankyou_message'
              )
            "
          />
        </p>
        <CardProductSmall :contract="contract" dense :useBorder="true" />
      </div>
    </div>
    {{ /* Footer */ }}
    <template v-slot:footer>
      <div
        class="grid justify-center sm:flex sm:justify-between w-full items-center"
      >
        <n-button-link
          bold
          @click.native="close"
          v-if="step === 1"
          class="mt-5 sm:mt-0 sm:text-base text-xs order-last sm:order-first"
        >
          <n-html
            :text="
              $t('insurances.cancel_modal.preview.buttons.back_to_overview')
            "
          />
        </n-button-link>

        <n-button-link
          bold
          @click.native="previousStep"
          v-else-if="step < 6"
          class="order-last sm:order-first mt-5 sm:mt-0 flex justify-center sm:text-base text-xs"
        >
          <n-html :text="$t('insurances.cancel_modal.buttons.previous')" />
        </n-button-link>
        <n-button-link
          bold
          @click.native="previousStep"
          v-else-if="step < 6"
          class="hidden sm:flex"
        >
          <n-html :text="$t('insurances.cancel_modal.buttons.previous')" />
        </n-button-link>
        <n-button-regular
          @click.native="
            $router.push({ name: 'insurances.new' }).catch(() => {})
          "
          v-else
        >
          <n-html :text="$t('insurances.cancel_modal.buttons.new_insurance')" />
        </n-button-regular>
        <n-button-regular
          @click.native="nextStep"
          :disabled="!stepIsValid"
          v-if="step < 5"
        >
          <n-html :text="$t('insurances.cancel_modal.buttons.next')" />
        </n-button-regular>
        <n-button-regular
          @click.native="submit"
          :disabled="!stepIsValid"
          v-else-if="step === 5"
        >
          <n-html
            :text="$t('insurances.cancel_modal.password.buttons.terminate')"
          />
        </n-button-regular>
        <div class="ml-auto" v-else>
          <n-button-regular @click.native="close">
            <n-html
              :text="
                $t('insurances.cancel_modal.preview.buttons.back_to_overview')
              "
            />
          </n-button-regular>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import CardProductSmall from "./../../components/products/card-product-small";
import format from "../../mixins/format";
import Modal from "@/components/theme/modal/modal";

export default {
  name: "components.insurances.modal-insurance-cancel",
  components: { Modal, CardProductSmall },
  mixin: [format],
  props: {
    value: Boolean,
    contract: Object,
    show: Boolean,
    onCloseModal: Function,
    openEditModal: Function
  },
  computed: {
    ...mapGetters({
      ClosingCodes: "contracts/getClosingCodes",
      ClosingDate: "contracts/getClosingDate",
      ClosingReasons: "contracts/getClosingReasons"
    }),
    stepIsValid() {
      if (this.step === 1) {
        return this.contract.late_payment_amount <= 0;
      } else if (this.step === 2) {
        return (
          this.form.reason_option &&
          this.form.reason_text &&
          this.form.reason_text.length < 250
        );
      } else if (this.step === 5) {
        return (
          this.form.has_agreed &&
          this.form.password === this.form.repeat_password
        );
      } else {
        return true;
      }
    },
    getTitle() {
      if (this.step === 6) {
        return this.$t("insurances.cancel_modal.success.title");
      }
      return this.$t("insurances.cancel_modal.title");
    },
    getClosingReasonDescription() {
      const closing_reason = this.ClosingReasons.find(
        r => r.reasonCode === this.form.reason_option
      );

      return closing_reason.description;
    },
    closingCodes() {
      let codes = [];
      codes.push({ value: null, key: "" });

      this.ClosingCodes.forEach(element => {
        codes.push({ value: element.closeCode, key: element.description });
      });

      return codes;
    },
    closingReasons() {
      let reasons = [];
      reasons.push({ value: null, key: "" });

      this.ClosingReasons.forEach(element => {
        reasons.push({
          value: element.reasonCode,
          key: this.getTranslationForReasonCode(element.reasonCode)
        });
      });

      return reasons;
    }
  },
  data: () => ({
    step: 1,
    closing_code: null,
    closing_date: null,
    step_3_reason_options: ["DUUR", "WAARD", "NIET IN BEZIT", "INBOEDEL"],
    form: {
      reason_option: "",
      reason_text: "",
      password: "",
      repeat_password: "",
      has_agreed: false
    },
    errors: {
      message: ""
    }
  }),
  methods: {
    nextStep() {
      if (this.step === 2) {
        const closing_reason = this.ClosingReasons.find(
          r => r.reasonCode === this.form.reason_option
        );

        if (!this.step_3_reason_options.includes(closing_reason.reasonCode)) {
          this.step = 4;
        } else {
          this.step += 1;
        }
      } else {
        this.step += 1;
      }
    },
    previousStep() {
      if (this.step === 4) {
        const closing_reason = this.ClosingReasons.find(
          r => r.reasonCode === this.form.reason_option
        );

        if (this.step_3_reason_options.includes(closing_reason.reasonCode)) {
          this.step -= 1;
        } else {
          this.step = 2;
        }
      } else {
        this.step -= 1;
      }
    },
    close() {
      this.step = 1;
      this.closing_code = null;
      this.closing_date = null;
      this.form.reason_option = "";
      this.form.reason_text = "";
      this.form.password = "";
      this.form.has_agreed = false;

      this.onCloseModal();
      this.$gtag.event("Step 1: ContractCloseCancelModal", {
        contract_id: this.contract.navision_ext_id
      });
    },
    onClosingCodeChange(e) {
      this.closing_code = e.target.value;
    },
    onClosingReasonChange(e) {
      this.form.reason_option = e.target.value;
    },
    agreeToStayWithDiscount() {
      const payload = {
        id: this.contract.id,
        data: {
          contract_id: this.contract.navision_ext_id,
          reason_code: this.form.reason_option,
          description: this.form.reason_text
        }
      };
      this.$store.dispatch("contracts/requestContractDiscountToStay", payload);
      this.step = 7;
    },
    getTranslationForReasonCode(code) {
      switch (code) {
        case "AANKOOP RETOUR":
          return this.$t("insurances.cancel_modal.description.reasons.retour");
        case "DUUR":
          return this.$t(
            "insurances.cancel_modal.description.reasons.too_expensive"
          );
        case "INBOEDEL":
          return this.$t(
            "insurances.cancel_modal.description.reasons.home_insurance"
          );
        case "INFO":
          return this.$t(
            "insurances.cancel_modal.description.reasons.misled_information_store"
          );
        case "WAARD":
          return this.$t(
            "insurances.cancel_modal.description.reasons.value_device"
          );
        case "NIET IN BEZIT":
          return this.$t(
            "insurances.cancel_modal.description.reasons.not_in_possession"
          );
        case "SCHADE":
          return this.$t("insurances.cancel_modal.description.reasons.damage");
        case "NIEUW OBJECT":
          return this.$t(
            "insurances.cancel_modal.description.reasons.new_insured_device"
          );
        case "NIEUWE VERZ":
          return this.$t(
            "insurances.cancel_modal.description.reasons.new_insurance_harmony"
          );
        case "NIEUWE VERZ EXT":
          return this.$t(
            "insurances.cancel_modal.description.reasons.new_insurance"
          );
        case "OVERIG":
          return this.$t("insurances.cancel_modal.description.reasons.other");
        case "ONBEKEND":
          return this.$t("insurances.cancel_modal.description.reasons.other");
        case "WIJZIGING VERZ":
          return this.$t(
            "insurances.cancel_modal.description.reasons.wijziging_verz"
          );
        default:
          return "";
      }
    },
    submit() {
      this.errors.message = "";
      const payload = {
        id: this.contract.id,
        data: {
          closing_code: this.closing_code,
          reason_code: this.form.reason_option,
          description: this.form.reason_text
        }
      };

      this.$store
        .dispatch("contracts/cancel", payload)
        .then(result => {
          this.closing_date = result.data.value;
          this.step = 6;
        })
        .catch(e => {
          if (e.response.data.error === "OPEN_REQUEST") {
            this.errors.message = this.$t(
              "insurances.cancel_modal.error.message.open_request"
            );
          } else {
            this.errors.message = this.$t(
              "insurances.cancel_modal.error.unknown_error"
            );
          }
        })
        .finally(() => this.$store.dispatch("contracts/getAll"));
    }
  },
  watch: {
    step(n) {
      const payload = {
        contract_id: this.contract.navision_ext_id
      };
      if (n) {
        switch (n) {
          case 2:
            this.$store.dispatch("contracts/getContractClosingCodes", payload);
            this.$gtag.event("Step 2: ContractEndDateAndReasoning", {
              contract_id: this.contract.navision_ext_id
            });
            break;
          case 3:
            this.$gtag.event("Step 3: ContractFollowup", {
              contract_id: this.contract.navision_ext_id,
              reason: this.form.reason_option,
              description: this.form.reason_text
            });
            break;
          case 4:
            this.$gtag.event("Step 4: ContractOverview", {
              contract_id: this.contract.navision_ext_id
            });
            break;
          case 5:
            this.$gtag.event("Step 5: ContractAuthorization", {
              contract_id: this.contract.navision_ext_id
            });
            break;
          case 6:
            this.$gtag.event("Step 5: ContractSuccessfullyCancelled", {
              contract_id: this.contract.navision_ext_id
            });
            break;
        }
      }
    },
    closing_code(n) {
      if (n) {
        let close_date_payload = {
          contract_id: this.contract.navision_ext_id,
          close_code: this.closing_code
        };

        this.$store.dispatch(
          "contracts/getContractCloseDate",
          close_date_payload
        );
        this.$store.dispatch(
          "contracts/getContractClosingReasons",
          this.closing_code
        );
      }
    },
    value(val) {
      this.$emit("input", val);
      if (val === false) {
        setTimeout(() => (this.step = 1), 200);
      }
    }
  }
};
</script>
